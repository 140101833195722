<script lang="ts">
  import type { Global, SanityImageAsset } from "$generated/sanity";
  import { imgProps } from "$src/lib/sanity";
  import { parallax } from "@hyperfocal/actions";
  import { media } from "@hyperfocal/design/styles/media";
  import { random } from "lodash-es";

  type HomeHeroProps = {
    title: string;
    photos: Global["photos"];
  };

  const { title, photos }: HomeHeroProps = $props();

  const bounds: { width: [number, number]; height: [number, number] } =
    $derived.by(() => {
      let width: [number, number];
      let height: [number, number];

      if ($media?.["3xl"]) {
        width = [5, 15];
        height = [15, 25];
      }

      if ($media?.["2xl"]) {
        width = [10, 20];
        height = [15, 20];
      }

      if ($media?.xl) {
        width = [15, 30];
        height = [25, 35];
      }

      if ($media?.lg) {
        width = [20, 35];
        height = [25, 40];
      }

      if ($media?.md) {
        width = [25, 40];
        height = [20, 30];
      }

      width = [20, 35];
      height = [30, 45];

      return $media?.portrait
        ? { width: height, height: width }
        : { width, height };
    });
</script>

<style>
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .hero {
    position: relative;
    overflow: hidden;
    grid-column: 1 / -1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.8;
      z-index: var(--layer-1);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - var(--app-header-height));
    min-height: var(100svh - var(--app-header-height));
    z-index: var(--layer-2);
    padding-top: 15vh;
    @media (--sm) {
      padding-top: 0;
      justify-content: center;
    }
  }

  .title {
    font: var(--display-xl);
    letter-spacing: var(--letter-sm);
    font-weight: var(--weight-bold);
    text-align: center;
    z-index: var(--layer-1);
    pointer-events: none;
    @media (--sm) {
      margin-bottom: 15vh;
    }
    @media (--md) {
      font: var(--display-2xl);
      letter-spacing: var(--letter-sm);
    }
    @media (--xl) {
      font: var(--display-3xl);
      letter-spacing: var(--letter-sm);
      max-width: 18ch;
    }
  }

  .photos {
    position: absolute;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    top: 0;
    left: -20%;
    width: 140%;
    height: 100%;
    gap: var(--size-10);
    margin-top: var(--size-4);
    @media (--md) {
      margin-top: var(--size-12);
      gap: var(--size-12);
    }
    @media (--lg) {
      gap: var(--size-16);
    }
    @media (--2xl) {
      gap: var(--size-20);
    }
  }

  .photo {
    transform-origin: center;
    object-fit: contain;
    opacity: 0;
    animation: fadeIn forwards 800ms linear;
    @media not all and (--xs) {
      transform: none !important;
    }
  }

  .photo-image {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
</style>

<section class="hero">
  <div class="--grid-page">
    <div class="content">
      <h1 class="title">{title}</h1>
    </div>
  </div>
  <div class="photos">
    {#each photos as photo}
      {@const width = Math.round(random(...bounds.width))}
      {@const height = Math.round(random(...bounds.height))}
      <div
        class="photo"
        style:animation-delay="{Math.round(random(250, 750))}ms"
        style:transform="translate({random(-10, 10)}%, {random(-20, 20)}%)"
        use:parallax={{
          props: {
            [`--${photo._key}`]: {
              from: `${random(-25, -10)}%`,
              to: `${random(15, 25)}%`,
            },
          },
        }}
      >
        <img
          {...imgProps(photo.asset as unknown as SanityImageAsset)}
          class="photo-image"
          style:transform="translateY(var(--{photo._key}))"
          style:max-width="{width}vw"
          style:max-height="{height}vh"
          sizes="{width}vw"
        />
      </div>
    {/each}
  </div>
</section>
